<template>
  <div class="tabs">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->
      <!-- <el-tab-pane label="任务维度" name="first">
        <MissionList></MissionList>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="用户维度" name="second"> -->
        <UserList></UserList>
      <!-- </el-tab-pane> -->
    <!-- </el-tabs> -->
  </div>
</template>
<script>
import MissionList from './missionList.vue'
import UserList from './userList.vue'
export default {
  name: 'Index',
  components: { MissionList , UserList },
  data() {
    return {
      activeName: 'first',
      options:[],
      value:''
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  background: #ffffff;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.tabs{
  background: #ffffff;
  padding: 10px 20px;
  margin: 10px;
  height: calc(100vh - 195px);
  border-radius: 10px;
}
</style>
